<template>
  <Transition enter-active-class="duration-500 ease-out"
              enter-from-class="transform opacity-0"
              enter-to-class="opacity-100"
              leave-active-class="duration-200 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="transform opacity-0"
  >
    <div v-if="props.open"
         class="fixed inset-0 z-30 max-h-screen px-2 pb-20 overflow-y-auto bg-gray-600 lg:hidden pt-14"
    >
      <div class="bg-pink-purple absolute top-14 left-[-400px] w-[512px] h-[512px] blur-[50px] duration-500 ease-in-out rounded-full will-change-transform"
           :style="{ transform: `translate3d(${submenuIndex ? '96px' : 0}, 0, 0)` }"
      />

      <ol class="relative space-y-4 font-medium text-gray-100 pt-14">
        <MenuMobileSubmenu v-for="link in items"
                           :key="link.id"
                           :link="link"
                           @submenu="handleSubClick"
                           @close="$emit('close')"
                           :index="link.id"
                           :open="submenuIndex === link.id"
        />
      </ol>

      <!-- Mobile Social menu, fixed on bottom -->
      <div className="fixed bottom-0 left-0 right-0 py-6 mt-4 flex justify-center space-x-6 backdrop-blur-[10px]">
        <NuxtLink v-for="link in social"
                  :key="link.id"
                  :to="link.url"
                  @click="handleClick(link.title)"
        >
          <span class="sr-only">{{ link.title }}</span>
          <component :is="`Icon${link.icon}`"
                     class="w-6 h-6 text-white"
          />
        </NuxtLink>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import type { MenuItemType, MenuSocialItemType } from '../../types/menu';
import { useNuxtApp } from '#imports';

const submenuIndex = ref<boolean | string>(false);

interface MobileProps {
  open: boolean;
  items: Array<MenuItemType>;
  social: Array<MenuSocialItemType>;
}

const props = defineProps<MobileProps>();
defineEmits(['close']);

const { $event } = useNuxtApp();

onMounted(() => {
  document.body.classList.remove('overflow-hidden');
  document.documentElement.classList.remove('overflow-hidden');
});

watch(
  () => props.open,
  current => {
    if (current) {
      document.body.classList.add('overflow-hidden');
      document.documentElement.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
      document.documentElement.classList.remove('overflow-hidden');
    }
  }
);

/**
 * Handle submenu component click on mobile
 *
 * We set the submenuIndex to the index of the clicked submenu, so it is opened
 * Only one submenu can be opened at a time
 * @param {string} n
 */
function handleSubClick(n: string) {
  if (submenuIndex.value === n) {
    submenuIndex.value = false;
  } else {
    submenuIndex.value = n;
  }
}

function handleClick(linkTitle: string) {
  $event('Link', { props: { 'Header': linkTitle } });
}
</script>
